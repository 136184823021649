<template>
  <div class="page">
    <v-container>
      <h1>Risultati ricerca</h1>
      <strong class="text-body-1" v-if="page.totItems > 0">
        {{ page.totItems }} articoli trovati per
        <span class="highlight">"{{ query }}"</span>
      </strong>
      <div v-else><p>Nessun risultato trovato</p></div>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="post in postList"
          :key="post.id"
        >
          <search-card :post="post" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import cmService from "~/service/cmService";
import clickHandler from "~/mixins/clickHandler";
import page from "~/mixins/page";
import SearchCard from "@/components/wordpress/SearchCard.vue";

export default {
  components: { SearchCard },
  mixins: [clickHandler, page],
  //subtypes
  // "subtype non è uno di post, page, blocks, recipe, convenzione, faq, percorsi_tematici, prima_card, testimonial, wpsl_stores, category, post_tag, ingredient, course, cuisine, wpurp_keyword, categoria_primacard, categoria_convenzioni, categoria_faq, e any."
  data() {
    return {
      query: null,
      subtype: [
        "post",
        "recipe",
        "faq",
        "convenzione",
        "percorsi_tematici",
        "prima_card"
      ],
      page: 1,
      postList: []
    };
  },
  methods: {
    async reload() {
      cmService.search(this.query, this.subtype, this.page, 24).then(res => {
        this.postList = res.data;
        this.page = res.page;
      });
    }
  },
  created() {
    this.query = this.$route.query.q;
    if (this.$route.query.subtype) {
      this.subtype = this.$route.query.subtype;
    }
    this.reload();
  },
  watch: {
    "$route.params.q": function(value) {
      this.query = value;
      this.reload();
    }
  }
};
</script>
