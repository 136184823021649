<template>
  <v-card
    outlined
    class="fill-height my-2 rounded-md d-flex flex-column post-card"
    :to="{
      name: routeName,
      params: { postName: post.slug }
    }"
  >
    <v-img
      height="250"
      max-height="250"
      :src="post.featured_image_url"
      :alt="`Immagine: ${post.title}`"
    ></v-img>
    <v-card-subtitle class="py-0">
      {{ postType }}
    </v-card-subtitle>
    <v-card-title
      class="card-description pa-3 body-1 font-weight-bold d-flex align-start"
      >{{ post.title }}</v-card-title
    >
    <v-card-text class="post-content card-description-secondary">
      {{ post.exerpt }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PostCard",
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  computed: {
    routeName() {
      switch (this.post.subtype) {
        case "post":
          return "ArticleDetail";
        case "convenzione":
          return "ConvenzioniList";
        case "faq":
          return "FaqiList";
        case "recipe":
          return "RecipeDetail";
        case "prima_card":
          return "PrimaCardList";
        default:
          return "Page";
      }
    },
    postType() {
      switch (this.post.subtype) {
        case "post":
          return "Articolo";
        case "convenzione":
          return "Convensioni basko";
        case "faq":
          return "FaqList";
        case "recipe":
          return "Ricetta";
        case "prima_card":
          return "PrimaCardList";
        default:
          return "Pagina";
      }
    }
  }
};
</script>
<style lang="scss">
.post-card.v-card {
  .v-card__subtitle {
    font-size: 0.8rem;
    color: var(--v-primary-base);
    font-weight: 800;
    text-transform: uppercase;
  }
}
</style>
